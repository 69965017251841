@import "~rsuite/dist/rsuite.min.css";

/* @enable-dark-mode: true; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100%;
  padding: 1rem;
}

.iconSpin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
  align-items: center;
  justify-content: center;
  display: flex 1;
  margin-top: 3rem;
  text-align: center;
}

.tagInput {
  text-align: left;
  margin-top: 10px;
}

.iconSpinInline {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
  align-items: bottom;
  margin-top: 1em;
  /* justify-content: center;
  display: flex 1;
  
  text-align: center; */
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.newsDiv {
  display: flex 1;
  overflow-wrap: break-word;
  max-width: 99vw;
  text-overflow: clip;
  margin-top: 1rem;
  border: 0.15rem;
  border-style: solid;
  border-radius: 1rem;
  padding: 1rem;
  background-color: lightgray;
}

.banner {
  display: flex 1;
  overflow-wrap: break-word;
  max-width: 99vw;
  text-overflow: clip;
  margin-top: 1rem;
  border: 0rem;
  border-style: solid;
  border-radius: 1rem;
  padding: 1rem;
  background-color: lightsteelblue;
}

/* .centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.modalButtonDiv {
  user-select: none;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: baseline;
}
